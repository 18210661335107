import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { iStageClient } from "../api/ApiClient";
import { IStage } from "../api/payloads/Stage";
import StageDetailsSliceProps from "./props/StageDetailsSliceProps";
import { ThunkActionState } from "../helpers/ThunkActionState";

const sliceName = "venueDetails";

const initialState: StageDetailsSliceProps = {
    loadingStageState: ThunkActionState.idle,
};

export const loadVenueAsync = createAsyncThunk(`${sliceName}/loadVenue`, async (venueId: string, { dispatch }) => {
    const venue = await iStageClient.getVenue(venueId);
    dispatch(stageDetailsSlice.actions.selectStage(venue));
});

const stageDetailsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        selectStage: (state: StageDetailsSliceProps, action: PayloadAction<IStage>) => {
            state.stage = action.payload;
        },
        reset: () => initialState,
    },
    extraReducers: {
        [loadVenueAsync.pending.toString()]: (state: StageDetailsSliceProps) => {
            state.loadingStageState = ThunkActionState.pending;
        },
        [loadVenueAsync.rejected.toString()]: (state: StageDetailsSliceProps) => {
            state.loadingStageState = ThunkActionState.rejected;
        },
        [loadVenueAsync.fulfilled.toString()]: (state: StageDetailsSliceProps) => {
            state.loadingStageState = ThunkActionState.fulfilled;
        },
    },
});

export const {
    selectStage,
    reset,
} = stageDetailsSlice.actions;

export default stageDetailsSlice.reducer;