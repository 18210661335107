import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { iStageClient } from '../api/ApiClient';
import { IStage } from "../api/payloads/Stage";
import { IStageStoreProps } from "../common/props/store/IStageProps";
import { ThunkActionState } from "../helpers/ThunkActionState";

const sliceName = "venues";

const initialState: IStageStoreProps = {
    loadingStagesState: ThunkActionState.idle,
    processingStageState: ThunkActionState.idle,
    loadingCurrentStageState: ThunkActionState.idle,
    loadingRecommendedStagesState: ThunkActionState.idle,
    launchingStageState: ThunkActionState.idle,
    closingCurrentStageState: ThunkActionState.idle,
};

export class StageHelper {
    loadStagesList = createAsyncThunk(`${sliceName}/loadVenues`, async (_, { dispatch }) => {
        let stages = await iStageClient.getStagesList();
        dispatch(stagesSlice.actions.stagesListLoaded(stages));
    });
    
    loadRecommendedStagesAsync = createAsyncThunk(`${sliceName}/loadRecommendedVenues`, async (_, { dispatch }) => {
        const recommendedStages = await iStageClient.getRecommendedStages();
        dispatch(stagesSlice.actions.recommendedStagesLoaded(recommendedStages));
    });

    selectVenue = (state: IStageStoreProps, venueId: string | undefined) : IStage | undefined => {
        console.log(venueId);
        if (venueId === undefined) return undefined;
        return state.stages?.find(x=>x.id === venueId);
    }
}

const stagesSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        stagesListLoaded: (state: IStageStoreProps, action: PayloadAction<Array<IStage>>) => {
            state.stages = action.payload;
        },
        recommendedStagesLoaded: (state: IStageStoreProps, action: PayloadAction<IStage[]>) => {
            state.recommendedStages = action.payload;
        },
    },
    extraReducers: {
        [`${sliceName}/loadVenues/pending`]: (state: IStageStoreProps) => {
            state.loadingStagesState = ThunkActionState.pending;
        },
        [`${sliceName}/loadVenues/fulfilled`]: (state: IStageStoreProps) => {
            state.loadingStagesState = ThunkActionState.fulfilled;
        },
        [`${sliceName}/loadVenues/rejected`]: (state: IStageStoreProps) => {
            state.loadingStagesState = ThunkActionState.rejected;
        },

        [`${sliceName}/loadRecommendedVenues/pending`]: (state: IStageStoreProps) => {
            state.loadingRecommendedStagesState = ThunkActionState.pending;
        },
        [`${sliceName}/loadRecommendedVenues/fulfilled`]: (state: IStageStoreProps) => {
            state.loadingRecommendedStagesState = ThunkActionState.fulfilled;
        },
        [`${sliceName}/loadRecommendedVenues/rejected`]: (state: IStageStoreProps) => {
            state.loadingRecommendedStagesState = ThunkActionState.rejected;
        },
    }
});

const reducer = stagesSlice.reducer;
const stageHelper = new StageHelper();

export {
    reducer,
    stageHelper
};