import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import IUserMeetingsStore from "./props/IUserMeetingsStore";
import { iUserClient } from "../../../../api/ApiClient";
import Event from "../../../../api/payloads/Event";
import PaginatedList from "../../../../api/payloads/PaginatedList";
import { EventType } from "../../../../api/payloads/EventType";
import { EventSessionStatus } from "../../../../api/payloads/EventSessionStatus";
import { RootState } from "app/store";

const availablePageSizes = [ 25, 50, 75, 100 ];

const initialState: IUserMeetingsStore = {
    loadingMeetingsState: "idle",
    meetings: [],
    currentPage: 0,
    totalPagesCount: 0,
    availablePageSizes: availablePageSizes,
    pageSize: availablePageSizes[0],
    meetingType: null,
    meetingStatus: EventSessionStatus.Live,
};

const sliceName = "userMeetings";

export const loadMeetingsAsync = createAsyncThunk(sliceName + "/loadingMeetings", async (page: number, { dispatch, getState }) => {
    const {pageSize, meetingStatus, meetingType} = (getState() as RootState).user.meetings;
    const meetings = await iUserClient.getUserMeetings(page * pageSize, pageSize, meetingType, meetingStatus);
    dispatch(userMeetingsSlice.actions.meetingsLoaded(meetings));
});

export const setPageSizeAsync = createAsyncThunk(sliceName + "/setPageSizes", async (pageSize: number, { dispatch }) => {
    dispatch(userMeetingsSlice.actions.setPageSize(pageSize));
    await dispatch(loadMeetingsAsync(0));
});

export const setMeetingTypeAsync = createAsyncThunk(sliceName + "/setMeetingType", async (meetingType: EventType | null, { dispatch }) => {
    dispatch(userMeetingsSlice.actions.setMeetingType(meetingType));
    await dispatch(loadMeetingsAsync(0));
});

export const setMeetingStatusAsync = createAsyncThunk(sliceName + "/setMeetingStatus", async (meetingStatus: EventSessionStatus | null, { dispatch }) => {
    dispatch(userMeetingsSlice.actions.setMeetingStatus(meetingStatus));
    await dispatch(loadMeetingsAsync(0));
});

const userMeetingsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        meetingsLoaded: (state: IUserMeetingsStore, action: PayloadAction<PaginatedList<Event>>) => {
            state.meetings = action.payload.items;
            state.totalPagesCount = Math.ceil(action.payload.totalCount / state.pageSize);
            state.currentPage = action.payload.offset / state.pageSize;
        },
        setPageSize: (state: IUserMeetingsStore, action: PayloadAction<number>) => {
            state.pageSize = action.payload;
        },
        setMeetingType: (state: IUserMeetingsStore, action: PayloadAction<EventType | null>) => {
            state.meetingType = action.payload;
        },
        setMeetingStatus: (state: IUserMeetingsStore, action: PayloadAction<EventSessionStatus | null>) => {
            state.meetingStatus = action.payload;
        },
        preloadMeeting: (state: IUserMeetingsStore, action: PayloadAction<Event>) => {
        },
        reset: () => initialState,
    },
    extraReducers: {
        [loadMeetingsAsync.pending.toString()]: (state: IUserMeetingsStore) => {
            state.loadingMeetingsState = "loading";
        },
        [loadMeetingsAsync.fulfilled.toString()]: (state: IUserMeetingsStore) => {
            state.loadingMeetingsState = "succeeded";
        },
        [loadMeetingsAsync.rejected.toString()]: (state: IUserMeetingsStore) => {
            state.loadingMeetingsState = "failed";
        },
    }
});

export const {
    reset,
    preloadMeeting,
} = userMeetingsSlice.actions;

export default userMeetingsSlice.reducer;