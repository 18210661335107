import { RootState } from "app/store";
import { slice } from "./slice";
import * as thunks from "./thunks";

export * from "./slice";

export const actions = {
  ...slice.actions,
  ...thunks,
};

export const reducer = slice.reducer;

export const visualSettingsSelector = (state: RootState) => state.visualSettings.visualSettings;
export const hasSettingsSelector = (state: RootState) => !!state.visualSettings.visualSettings.length;
