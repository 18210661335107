import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import EventVenueInfo from "api/payloads/EventVenueInfo";
import Event from "api/payloads/Event";
import { EventType } from "api/payloads/EventType";
import Logo from "api/payloads/Logo";
import { Participant } from "api/payloads/User";
import { IEventAdditionalInfo, IPreMeetingStore } from "common/props/store/IPreMeetingStore"
import { preloadMeeting, meetingLoaded } from "slices/MeetingDetailsSlice";
import { VenueLayout } from "api/payloads/VenueMetadata";

const sliceName = "preMeetingSlice";

const initialState: IPreMeetingStore = {
    listenersAdditionalInfo: {
        description: "",
        title: "",
        participants: new Array<Participant>(),
    },
    speakersAdditionalInfo: {
        description: "",
        title: "",
        participants: new Array<Participant>()
    },
    type: EventType.DualCall,
    rtmpUrl: "",
    useHighResolutionAIModel: false,
    venueLayout: VenueLayout.SingleSpeaker,
};

const preMeetingSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        setSelectedVenue: (state: IPreMeetingStore, action: PayloadAction<EventVenueInfo>) => {
            state.venue = action.payload;
        },
        setEventSettings: (state: IPreMeetingStore, action: PayloadAction<{
            type: EventType,
            rtmpUrl?: string,
            useHighResolutionAIModel: boolean,
        }>) => {
            const { payload } = action;
            state.type = payload.type;
            state.rtmpUrl = payload.rtmpUrl;
            state.useHighResolutionAIModel = payload.useHighResolutionAIModel;
        },
        setLogoFile: (state: IPreMeetingStore, action: PayloadAction<File | undefined>) => {
            state.logoFile = action.payload;
            state.savedLogo = undefined;
        },
        setSlates: (state: IPreMeetingStore, action: PayloadAction<File[] | undefined>) => {
            state.slates = action.payload;
            state.savedLogo = undefined;
        },
        setLanguage: (state: IPreMeetingStore, action: PayloadAction<string | undefined>) => {
            state.language = action.payload;
        },
        setVenueLayout: (state: IPreMeetingStore, action: PayloadAction<VenueLayout>) => {
            state.venueLayout = action.payload;
        },
        setSavedLogo: (state: IPreMeetingStore, action: PayloadAction<Logo | undefined>) => {
            state.savedLogo = action.payload;
            state.logoFile = undefined;
        },
        setListenersAdditionalInfo: (state: IPreMeetingStore, action: PayloadAction<IEventAdditionalInfo>) => {
            state.listenersAdditionalInfo = action.payload;
        },
        setSpeakersAdditionalInfo: (state: IPreMeetingStore, action: PayloadAction<IEventAdditionalInfo>) => {
            state.speakersAdditionalInfo = action.payload;
        },
    },
    extraReducers: {
        [preloadMeeting.toString()]: (state: IPreMeetingStore, action: PayloadAction<Event | null>) => {
            const { payload } = action;
            if (payload) {
                state.type = payload.type;
                state.rtmpUrl = payload.broadcastInfo?.url;
                state.venue = payload.venue;
                state.listenersAdditionalInfo = payload.listenersCallAdditionalInfo;
                state.speakersAdditionalInfo = payload.speakersCallAdditionalInfo;
                state.useHighResolutionAIModel = payload.settings.useHighResolutionAIModel;
                state.savedLogo = payload.logo;
                state.language = payload.language;
                state.venueLayout = payload.settings.venueLayout;
            }
        },
        [meetingLoaded.toString()]: (state: IPreMeetingStore, action: PayloadAction<Event | null>) => {
            const { payload } = action;
            if (payload) {
                state.type = payload.type;
                state.rtmpUrl = payload.broadcastInfo?.url;
                state.venue = payload.venue;
                state.listenersAdditionalInfo = payload.listenersCallAdditionalInfo;
                state.speakersAdditionalInfo = payload.speakersCallAdditionalInfo;
                state.useHighResolutionAIModel = payload.settings.useHighResolutionAIModel;
                state.savedLogo = payload.logo;
                state.language = payload.language;
                state.venueLayout = payload.settings.venueLayout;
            }
        },
    }
});

export const {
    setListenersAdditionalInfo,
    setSpeakersAdditionalInfo,
    setSelectedVenue,
    setLanguage,
    setVenueLayout,
    setLogoFile,
    setSlates,
    setEventSettings,
    setSavedLogo,
} = preMeetingSlice.actions;

export default preMeetingSlice.reducer;