import React from "react";
import * as Oidc from "oidc-client-ts";
import { authHelper, setIsAccessAllowed } from "slices/AuthSlice";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { WithRouterProps } from "react-router";
import { eventEmitter, EventTypes } from "../../api/ApiClient"
import { AppType } from "features/AppType";
import { Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import AuthContext from "./AuthContext";
import Loader from "components/Loader";

const mapDispatchToProps = {
    signIn: authHelper.signIn,
    signOut: authHelper.signOut,
    setIsAccessAllowed: setIsAccessAllowed,
};

const connector = connect(null, mapDispatchToProps, null, { forwardRef: true });

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ComponentProps extends PropsFromRedux, RouteComponentProps {
    extraQueryParams?: Record<string, string | number | boolean>,
    children?: React.ReactNode | React.ReactNode[],
};

interface IProps extends ComponentProps {
    authority: string,
    applicationType: AppType,
    onTokenWillRefresh?: () => Promise<void>,
};

interface IState {
    isUserSignedIn: boolean,
    isDialogShown: boolean,
    title: string,
    content: string,
};

class AuthProvider extends React.Component<IProps & WithRouterProps<any>, IState> {
    private static readonly CallbackSignIn: string = "callbackSignIn";
    private static readonly CallbackSignOut: string = "callbackSignOut";

    private _isSignoutInProcess: boolean = false;
    private userManager: Oidc.UserManager;

    constructor(props: IProps) {
        super(props);
        this.state = {
            isUserSignedIn: false,
            isDialogShown: false,
            title: "",
            content: ""
        };
        const {
            match,
            authority,
            applicationType
        } = this.props;

        let baseUrl = `${window.location.origin}`;
        
        if(applicationType == AppType.TeamsApp){
            baseUrl+=match.url;
        }

        if (!baseUrl.endsWith("/")) {
            baseUrl += "/";
        }

        const settings: Oidc.UserManagerSettings = {
            authority: authority,
            client_id: process.env.REACT_APP_OIDC_CLIENT_ID ?? "",
            redirect_uri: `${baseUrl}${AuthProvider.CallbackSignIn}`,
            post_logout_redirect_uri: `${baseUrl}${AuthProvider.CallbackSignOut}`,
            revokeTokensOnSignout: true,
            response_type: process.env.REACT_APP_OIDC_RESPONSE_TYPE,
            scope: process.env.REACT_APP_OIDC_SCOPE,
            accessTokenExpiringNotificationTimeInSeconds: process.env.REACT_APP_OIDC_ACCESS_TOKEN_EXPIRATION_TIME ? parseInt(process.env.REACT_APP_OIDC_ACCESS_TOKEN_EXPIRATION_TIME) : undefined,
            automaticSilentRenew: false,
        };
        this.userManager = new Oidc.UserManager(settings);

        Oidc.Log.setLevel(Oidc.Log.DEBUG);
        Oidc.Log.setLogger(console);
    }

    async componentDidMount() {
        const {
            location,
            signIn,
            match,
            extraQueryParams,
            history,
        } = this.props;

        eventEmitter.on(EventTypes.InvalidToken, this.handleInvalidToken);
        eventEmitter.on(EventTypes.InvalidSubscription, this.handleInvalidSubscription);
        this.userManager.events.addAccessTokenExpiring(this.onAccessTokenExpiring);
        this.userManager.events.addAccessTokenExpired(this.onAccessTokenExpired);
        this.userManager.events.addUserSignedIn(this.onUserSignedIn);
        this.userManager.events.addUserSignedOut(this.onUserSignedOut);
        this.userManager.events.addUserLoaded(this.onUserLoaded);

        const isCallbackUri = location.pathname.includes("callback");

        if (isCallbackUri) {
            let returnUrl = match.url;
            if (location.pathname.includes(AuthProvider.CallbackSignIn)) {
                try {
                    const user = await this.userManager.signinRedirectCallback();
                    const state = (user?.state as any);
                    if (state?.returnUrl) {
                        returnUrl = state.returnUrl;
                    }
                }
                catch
                {
                    await this.signinRedirect("", extraQueryParams);
                }
            } else {
                await this.userManager.signoutRedirectCallback();
                await this.signinRedirect("", extraQueryParams);
            }

            history.replace(returnUrl);
        } else {
            let user = await this.userManager.getUser();
            if (user?.expired) {
                user = await this.refreshTokenAsync();
            }

            if (!user) {
                const path = location.pathname + location.search;
                await this.signinRedirect(path, extraQueryParams);
            } else {
                await this.signInUser(user);
            }
        }
    };

    componentWillUnmount() {
        this.userManager.events.removeAccessTokenExpiring(this.onAccessTokenExpiring);
        this.userManager.events.removeAccessTokenExpired(this.onAccessTokenExpired);
        this.userManager.events.removeUserSignedIn(this.onUserSignedIn);
        this.userManager.events.removeUserSignedOut(this.onUserSignedOut);
        this.userManager.events.removeUserLoaded(this.onUserLoaded);
        eventEmitter.removeListener(EventTypes.InvalidToken, this.handleInvalidToken);
        eventEmitter.removeListener(EventTypes.InvalidSubscription, this.handleInvalidSubscription);
    };

    public signOut = async () => {
        if (!this._isSignoutInProcess) {
            this._isSignoutInProcess = true;
            try {
                await this.userManager.signoutRedirect();

                this._isSignoutInProcess = false;
            } catch (error: any) {
                this._isSignoutInProcess = false;

                throw error;
            }
        }
    };

    private handleInvalidToken = async () => {
        const { applicationType } = this.props;
        if (applicationType == AppType.TeamsApp) {
            await this.signOut();
        } else if (applicationType == AppType.PortalApp) {
            this.showModal("Link Account Error"
                , "To use this feature you should firstly link your touchcast account to Microsoft account.")
        }
    };

    private handleInvalidSubscription = () => {
        const { setIsAccessAllowed } = this.props;
        setIsAccessAllowed(false);
    };

    private signinRedirect = async (returnUrl: string, extraQueryParams?: Record<string, string | number | boolean>) => {
        try {
            await this.userManager.signinRedirect({
                state: {
                    returnUrl: returnUrl,
                },
                extraQueryParams,
            });
        } catch (error: any) {
            console.log("signinRedirect");
            console.log(error);
        }
    };

    private refreshTokenIfNeededAsync = async () => {
        if (process.env.REACT_APP_OIDC_SILENT_RENEW === "true") {
            await this.refreshTokenAsync();
        }
    };

    private refreshTokenAsync = async (): Promise<Oidc.User | null> => {
        const { onTokenWillRefresh } = this.props;
        try {
            if (onTokenWillRefresh) {
                await onTokenWillRefresh();
            }

            return await this.userManager.signinSilent();
        } catch (error: any) {
            this.onSilentRenewError(error);
            return null;
        }
    };

    private onUserSignedIn = async () => {
        console.log("onUserManagerUserSignedIn")
        const user = await this.userManager.getUser();
        if (user) {
            await this.signInUser(user);
        }
    };

    private onUserSignedOut = async () => {
        console.log("onUserManagerUserSignedOut")
        const { signOut } = this.props;
        await signOut();
        this.userManager.removeUser();
    };

    private onAccessTokenExpiring = async () => {
        console.log("onUserManagerAccessTokenExpiring");
        await this.refreshTokenIfNeededAsync();
    };

    private onAccessTokenExpired = async () => {
        console.log("onAccessTokenExpired");
        await this.refreshTokenIfNeededAsync();
    };

    private showModal = (title: string, content: string) => {
        this.setState({
            isDialogShown: true,
            title: title,
            content: content
        })
    };

    private hideModal = () => {
        this.setState({
            isDialogShown: false,
            title: "",
            content: ""
        })
    };

    private signInUser = async (user: Oidc.User) => {
        const { signIn } = this.props;

        const accessToken = user.access_token;

        await signIn(accessToken);

        this.setState({
            ...this.state,
            isUserSignedIn: true,
        });
    }

    private onSilentRenewError = async (error: string) => {
        console.log("onUserManagerSilentRenewError");

        const {
            extraQueryParams,
        } = this.props;
        await this.userManager.removeUser();

        const path = location.pathname + location.search;
        await this.signinRedirect(path, extraQueryParams);
    };

    private onUserLoaded = async (user: Oidc.User) => {
        console.log("onUserLoaded");

        await this.signInUser(user);
    };


    render() {
        const {
            isUserSignedIn,
            isDialogShown,
            title,
            content,
        } = this.state;

        const dialogContentProps = {
            type: DialogType.normal,
            title: title,
            subText: content,
        };

        const modelProps = {
            isBlocking: true,
            topOffsetFixed: true
        };

        return (
            <AuthContext.Provider value={{
                refreshTokenAsync: async () => { await this.refreshTokenAsync() },
            }}>
                <>
                    {isUserSignedIn ? this.props.children : <Loader text="Signing you in..." />}
                    <Dialog hidden={!isDialogShown} onDismiss={this.hideModal} modalProps={modelProps} dialogContentProps={dialogContentProps}>
                        <DialogFooter>
                            <PrimaryButton text="Ok" onClick={this.hideModal} />
                        </DialogFooter>
                    </Dialog>
                </>
            </AuthContext.Provider>
        );
    }
}

export default connector(withRouter(AuthProvider));