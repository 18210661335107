import { createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import IStartNowStore from "./props/IStartNowStore";
import { iMeetingClient } from "../../../../api/ApiClient";
import StartMeetingNowRequest from "../../../../api/requests/StartMeetingNowRequest";
import Event from "../../../../api/payloads/Event";
import { ThunkActionState } from "helpers/ThunkActionState";

const initialState: IStartNowStore = {
    startMeetingNowState: ThunkActionState.idle,
};

export const startMeetingNowAsync = createAsyncThunk('meetings/startMeetingNow', async (request: StartMeetingNowRequest, { dispatch, rejectWithValue }) => {
    try {
        const meeting = await iMeetingClient.startEventNow(request);
        dispatch(startNowSlice.actions.meetingStarted(meeting));
        return meeting;
    } catch (error: any) {
        return rejectWithValue(error);
    }
});

const startNowSlice = createSlice({
    name: "startNow",
    initialState,
    reducers: {
        meetingStarted: (state: IStartNowStore, action: PayloadAction<Event>) => {
            state.startedMeeting = action.payload;
        },
        reset: () => initialState,
    },
    extraReducers: {
        [startMeetingNowAsync.pending.toString()]: (state: IStartNowStore) => {
            state.startMeetingNowState = ThunkActionState.pending;
        },
        [startMeetingNowAsync.rejected.toString()]: (state: IStartNowStore) => {
            state.startMeetingNowState = ThunkActionState.rejected;
        },
        [startMeetingNowAsync.fulfilled.toString()]: (state: IStartNowStore) => {
            state.startMeetingNowState = ThunkActionState.fulfilled;
        },
    }
});

export const {
    meetingStarted,
    reset,
} = startNowSlice.actions;

export default startNowSlice.reducer;