import { BlobClient } from "@azure/storage-blob";
import { TransferProgressEvent } from "@azure/core-http";

export const uploadFileAsync = async (
  blobUrl: string,
  data: Blob,
  onProgress?: (progress: TransferProgressEvent) => void
) => {
  const blobClient = new BlobClient(blobUrl);
  const blockBlobClient = blobClient.getBlockBlobClient();
  await blockBlobClient.uploadData(data, {
    onProgress: onProgress,
  });
};
