import { createAsyncThunk, createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import Logo from "api/payloads/Logo";
import { iUserClient } from '../api/ApiClient'
import { User } from "../api/payloads/User";
import { IUserStore } from "./props/IUserStore";
import { RootState } from "app/store";
import { MessageBarType } from "@fluentui/react";
import { getAuthTokenAsync } from "helpers/MicrosoftTeams";

const sliceName = "user";

const initialState: IUserStore = {
    isLogoDeleted: null
};

class UserHelper {
    checkOutUserInfo = createAsyncThunk(`${sliceName}/checkOutUserInfo`, async (_, { dispatch }) => {
        const user = await iUserClient.getUserInfo();
        dispatch(userSlice.actions.checkOutUserInfo(user));
    });

    checkOutUserInfoTeams = createAsyncThunk(`${sliceName}/checkOutUserInfoTeams`, async (_, { dispatch }) => {
        const authToken = await getAuthTokenAsync();
        const user = await iUserClient.getUserInfoTeams(authToken);
        dispatch(userSlice.actions.checkOutUserInfo(user));
    });

    loadSavedLogosAsync = createAsyncThunk(`${sliceName}/loadSavedLogosAsync`, async (_, { dispatch }) => {
        const savedLogos = await iUserClient.getLogos();
        dispatch(userSlice.actions.savedLogosLoaded(savedLogos));
    });

    removeLogo = createAsyncThunk(`${sliceName}/removeSavedLogo`, async (logoId: string, { dispatch }) => {
        const logo = await iUserClient.removeLogo(logoId);
        return logo
        // dispatch(userSlice.actions.savedLogoRemoved(logo));
    });

    clearIsLogoDeleted = createAsyncThunk(`${sliceName}/clearIsLogoDeleted`, async (_, { dispatch }) => {
        dispatch(userSlice.actions.clearIsLogoDeleted());
    });

    selectDefaultOrganizaiton = (state: IUserStore) => state.user?.organizations.find(organization => organization.id !== "6CAC4D91-8E12-4E80-B8D2-BF9F047A546A");
}

export const userSlice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        checkOutUserInfo: (state: IUserStore, action: PayloadAction<User>) => {
            state.user = action.payload;
        },
        savedLogosLoaded: (state: IUserStore, action: PayloadAction<Logo[]>) => {
            state.savedLogos = action.payload;
        },
        savedLogoRemoved: (state: IUserStore, action: PayloadAction<Logo>) => {
            state.savedLogos = state.savedLogos?.filter(l => l.id !== action.payload.id);
        },
        clearIsLogoDeleted: (state: IUserStore) => {
            state.isLogoDeleted = null;
        },
    },
    extraReducers: {
        [`${sliceName}/removeSavedLogo/fulfilled`]: (state, { payload }) => {
            state.savedLogos = state.savedLogos?.filter(l => l.id !== payload.id);
            state.isLogoDeleted = MessageBarType.success
        },
        [`${sliceName}/removeSavedLogo/rejected`]: (state) => {
            state.isLogoDeleted = MessageBarType.error
        }
    }
});

const reducer = userSlice.reducer;
const userHelper = new UserHelper();
const selectUsers = (state: RootState) => state.user

export const getSavedLogos = createSelector([selectUsers], (users) => users.general.savedLogos)
export const getIslogoDeleted = createSelector([selectUsers], (users) => users.general.isLogoDeleted)

export {
    reducer,
    userHelper
};