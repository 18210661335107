import { CancelToken } from "axios";

import { apiClient } from "api/ApiClient";
import { Slate, Slates } from ".";

export class SlatesApi {
    static async getSlates(cancelToken?: CancelToken): Promise<Slates> {
        return await apiClient.get<Slates>(`api/users/me/slates`, cancelToken);
    }

    static async createSlate(uploadId: string, cancelToken?: CancelToken): Promise<Slate> {
        return await apiClient.post<Slate>(`api/users/me/slate`, {
            uploadId: uploadId,
        }, cancelToken);
    }
    
    static async removeSlate(slateId: string, cancelToken?: CancelToken): Promise<Slate> {
        return await apiClient.delete<Slate>(`/api/users/me/slates/${slateId}`, cancelToken);
    }

    static async renameSlate(slateId: string, name: string, cancelToken?: CancelToken): Promise<Slate> {
        return await apiClient.patch<Slate>(`/api/users/me/slates/${slateId}`, {name}, cancelToken);
    }

    static async attachSlates(eventId: string,ids :Array<string>,cancelToken? :CancelToken): Promise<Event>{
        return await apiClient.patch<Event>(`api/meetings/${eventId}/slate`, {
            slates: ids,
        }, cancelToken);
    }
  }
