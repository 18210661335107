import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ScheduleMeetingStore } from './props/ScheduleMeetingStore';
import { iMeetingClient } from '../../../../api/ApiClient';
import { Participant } from "../../../../api/payloads/User";
import ScheduleMeetingRequest from "../../../../api/requests/ScheduleMeetingRequest";
import Event from "../../../../api/payloads/Event";

const initialState: ScheduleMeetingStore = {
    participants: [],
    scheduleMeetingLoadingState: 'idle',
};

export const sliceName = "scheduleMeeting";

export const scheduleMeetingAsync = createAsyncThunk(sliceName + "/schedullingMeeting", async (request: ScheduleMeetingRequest, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
        const response = await iMeetingClient.scheduleEvent(request);
        dispatch(scheduleMeetingSlice.actions.meetingScheduled(response));
        return response;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const selectIsUserInvited = (state: ScheduleMeetingStore, user: Participant): boolean =>
    state.participants.findIndex(u => user.id === u.id) !== -1;

const scheduleMeetingSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        meetingScheduled: (state: ScheduleMeetingStore, action: PayloadAction<Event>) => {
            state.createdMeeting = action.payload;
        },
        setParticipants: (state: ScheduleMeetingStore, action: PayloadAction<Participant[]>) => {
            state.participants = action.payload;
        },
        reset: () => initialState,
    },
    extraReducers: {
        [scheduleMeetingAsync.pending.toString()]: state => {
            state.scheduleMeetingLoadingState = 'loading';
        },
        [scheduleMeetingAsync.fulfilled.toString()]: state => {
            state.scheduleMeetingLoadingState = 'succeeded';
        },
        [scheduleMeetingAsync.rejected.toString()]: state => {
            state.scheduleMeetingLoadingState = 'failed';
        },
    }
});

export const {
    setParticipants,
    reset,
    meetingScheduled,
} = scheduleMeetingSlice.actions;

export default scheduleMeetingSlice.reducer;