import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import GeneralConfiguration from "api/payloads/GeneralConfiguration";
import { iUtilitiesClient } from "../api/ApiClient";
import GeneralStoreProps from "./props/GeneralStoreProps";

const sliceName = "general";
const initialState: GeneralStoreProps = {
};

export const getGeneralConfigurationAsync = createAsyncThunk(`${sliceName}/getGeneralConfiguration`, async (_, { dispatch }) => {
    const configuration = await iUtilitiesClient.getGeneralConfiguration();
    dispatch(slice.actions.setConfiguration(configuration));
});

const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        setConfiguration: (state: GeneralStoreProps, action: PayloadAction<GeneralConfiguration>) => {
        },
    },
});

export const {
    setConfiguration,
} = slice.actions;

export default slice.reducer;