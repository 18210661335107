import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthStoreProps } from './props/AuthStoreProps';
import { iAuthenticationClient } from '../api/ApiClient';
import SubscriptionInfo from "api/SubscriptionInfo";
import { CustomClaimTypes } from "api/CustomClaimTypes";
import { setConfiguration } from "./GeneralSlice";
import GeneralConfiguration from "api/payloads/GeneralConfiguration";
import { RootState } from "app/store";

const initialState: AuthStoreProps = {
};

const parseJwt = <T>(token: string, key: string): T => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload)[key];
};

export class AuthHelper {
    signIn = createAsyncThunk("AuthHelper/SignIn", async (token: string, { dispatch, getState }) => {
        const state = getState() as RootState;

        iAuthenticationClient.setAccessToken(token);

        dispatch(slice.actions.setAccessToken(token));

        const subscriptionInfo = parseJwt<SubscriptionInfo>(token, CustomClaimTypes.Subscription);
        let isAccessAllowed = true;
        if (state.auth.isSubscriptionFilterEnabled) {
            isAccessAllowed = subscriptionInfo?.isAccessAllowed ?? false;
        }

        dispatch(slice.actions.signedIn(isAccessAllowed));
    });

    signOut = createAsyncThunk("AuthHelper/SignOut", async (_, { dispatch }) => {
        iAuthenticationClient.setAccessToken(null);
        dispatch(slice.actions.signedOut());
    });
};

export const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        signedIn: (state: AuthStoreProps, action: PayloadAction<boolean>) => {
            const { payload } = action;

            state.isAuthenticated = true;
            state.isAccessAllowed = payload;
        },
        signedOut: (state: AuthStoreProps) => {
            state.isAuthenticated = false;
        },
        setIsAccessAllowed: (state: AuthStoreProps, action: PayloadAction<boolean>) => {
            state.isAccessAllowed = action.payload;
        },
        setAccessToken: (state: AuthStoreProps, action: PayloadAction<string>) => {
            state.accessToken = action.payload
        }
    },
    extraReducers: {
        [setConfiguration.toString()]: (state: AuthStoreProps, action: PayloadAction<GeneralConfiguration>) => {
            state.isSubscriptionFilterEnabled = action.payload.isSubscriptionFilterEnabled;
            state.authority = action.payload.authority;
            state.storage = action.payload.storage;
        },
    },
});

const reducer = slice.reducer;
const authHelper = new AuthHelper();
const {
    setIsAccessAllowed,
} = slice.actions;

export {
    reducer,
    authHelper,
    setIsAccessAllowed,
};