interface IProps {
    text?: string,
    size?: SizeValue,
    background?:string
};
import { Loader as RnsLoader, Provider,SizeValue,teamsTheme } from '@fluentui/react-northstar';
import * as React from 'react';

const Loader: React.FC<IProps> = (props) => {
    const { text,background, size } = props;
    return (
        <div style={{background: `${background ?? "transparent"}`, width:"100%", height:"100%"}}>
            <Provider theme={teamsTheme} className="w-100 h-100" >
                <div className="d-flex justify-content-center align-content-center w-100 h-100">
                    <RnsLoader labelPosition="below" label={text} size={size} />
                </div>
            </Provider>
        </div>

    );
};

export default Loader;
