import { ErrorCode } from "./ErrorCode";

export default class MetaverseApiError {
    errorCode: ErrorCode = ErrorCode.Unknown;
    payload: any;

    constructor(errorCode: ErrorCode, payload: any) {
        this.errorCode = errorCode;
        this.payload = payload;
    }
};