
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IVisualSetting } from "core/visualSettings";
import { loadVisualSettingsAsync } from "./thunks";

export interface VisualSettingsState {
    visualSettings: IVisualSetting[];   
}

const initialState: VisualSettingsState = {
    visualSettings: []
};

export const slice = createSlice({
  name: "visualSettings",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    updateSettingValues: (state: VisualSettingsState, action: PayloadAction<IVisualSetting>) => {
        const newSettings = state.visualSettings.map((setting) => (
            setting.id === action.payload.id ? action.payload : setting
        ))
        state.visualSettings = newSettings
    },
  },
  extraReducers: builder => {
    builder.addCase(loadVisualSettingsAsync.fulfilled, (state, { payload }) => {
        const visualSettings = payload.runtimeSettings.map((setting : IVisualSetting) => ({
            ...setting,
            settingsValues: setting.settingsValues?.map((value) => ({
                ...value,
                defaultValue: value.value
            }))
        }))

      return {
        ...state,
        visualSettings
      };
    });
  },
});
