import { createAsyncThunk } from "@reduxjs/toolkit";
import { Slate } from "core/slates";
import { SlatesApi } from "core/slates";

import PrepareUploadRequest from "api/requests/PrepareUploadRequest";
import { UploadTarget } from "api/payloads/UploadTarget";
import { apiClient, iUploadsClient } from "api/ApiClient";
import FileUploadStartResponse from "api/payloads/FileUploadStartResponse";
import { uploadFileAsync } from "app/AzureBlobClient";
import FileUploadFinishRequest from "api/requests/FileUploadFinishRequest";
import { actions } from ".";

export const loadSlatesAsync = createAsyncThunk("slates/load", async () => {
  const slates = await SlatesApi.getSlates();
  return slates;
});

export const removeSlatesAsync = createAsyncThunk(
  "slates/remove",
  async (id: string) => {
    const slates = await SlatesApi.removeSlate(id);
    return slates;
  }
);

interface renameSlatesAsyncProps {
  id: string;
  name: string;
}

export const renameSlatesAsync = createAsyncThunk(
  "slates/rename",
  async ({ id, name }: renameSlatesAsyncProps) => {
    const slates = await SlatesApi.renameSlate(id, name);
    return slates;
  }
);

export const uploadSlatesAsync = createAsyncThunk(
  "slates/upload",
  async (
    payload: {
      organizationId: string;
      slates: File[];
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { organizationId, slates } = payload;
      const slatesLists: Array<Slate> = [];
      if (!slates) return rejectWithValue("No slates to upload");

      await Promise.all(
        slates.map(async (file) => {
          const prepareUploadRequest: PrepareUploadRequest = {
            organizationId: organizationId,
            target: UploadTarget.Slate,
            filename: file.name,
          };

          let prepareUploadResponse = null;

          try {
            prepareUploadResponse = await apiClient.post(
              "/uploads/prepare",
              prepareUploadRequest
            );
          } catch (error) {
            prepareUploadResponse = await apiClient.prepareUpload({
              ...prepareUploadRequest,
              force: true,
            });
          }

          if (prepareUploadResponse) {
            const fileUploadStartResponse =
              await apiClient.get<FileUploadStartResponse>(
                prepareUploadResponse.uploadStartUrl
              );
            dispatch(
              actions.uploadStart([
                {
                  id: fileUploadStartResponse.file_id,
                  fileName: fileUploadStartResponse.upload_id,
                  url: "",
                },
              ])
            );

            await uploadFileAsync(
              fileUploadStartResponse.azure.sas_uri,
              file,
              (progress) => {
                dispatch(
                  actions.trackUploading({
                    id: fileUploadStartResponse.file_id,
                    progress: progress.loadedBytes / file.size,
                  })
                );
              }
            );

            const fileUploadFinishRequest: FileUploadFinishRequest = {
              file_id: fileUploadStartResponse.file_id,
            };

            await apiClient.post(
              prepareUploadResponse.uploadFinishUrl,
              fileUploadFinishRequest
            );

            const slate = await SlatesApi.createSlate(
              fileUploadStartResponse.file_id
            );

            dispatch(actions.uploadFinish(slate));
          }
        })
      );

      return slatesLists;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
