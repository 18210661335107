// import type { RootState } from "../index";
import { slice } from "./slice";
import * as thunks from "./thunks";
import { RootState } from "app/store";

export * from "./slice";
export const actions = {
  ...slice.actions,
  ...thunks,
};
export const reducer = slice.reducer;

export const defaultSlatesListSelector = (state: RootState) => state.slates.defaultSlates;
export const slatesListSelector = (state: RootState) => state.slates.slates;
export const slatesUploadingSelector = (state: RootState) =>
  state.slates.uploadingProgress;
