import { apiClient } from "api/ApiClient";
import { CancelToken } from "axios";
import { IVisualSetting } from '.'

export interface IVisualSettings {
    packageId: string;
    venueId: string;
    runtimeSettings: IVisualSetting[]
}

export class VisualSettingsApi {
    static async getVisualSettings(eventId: string, cancelToken?: CancelToken): Promise<IVisualSettings> {
        return await apiClient.get<IVisualSettings>(`/api/meetings/${eventId}/venuesettings`, cancelToken);
    }
}