import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ThunkActionState } from "helpers/ThunkActionState";
import { iPlayerClient } from '../../api/ApiClient';
import Event from "../../api/payloads/Event";
import {IPlayerStore} from './props/IPlayerStore';

const initialState: IPlayerStore = {
    loadingMeetingState: ThunkActionState.idle,
};

export const getOutputUrl = createAsyncThunk("player/getOutputUrl", async (eventId: string, { dispatch }) => {
    const meeting = await iPlayerClient.getOutputUrl(eventId);
    dispatch(playerSlice.actions.outputUrlReceived(meeting));
});

const playerSlice = createSlice({
    name: "meetingDetails",
    initialState,
    reducers: {
        outputUrlReceived: (state:IPlayerStore, action : PayloadAction<Event>) => {
            state.meeting = action.payload;
        },
    },
    extraReducers: {
        [getOutputUrl.fulfilled.toString()]: (state: IPlayerStore) => {
            state.loadingMeetingState = ThunkActionState.fulfilled;
        },
        [getOutputUrl.rejected.toString()]: (state: IPlayerStore) => {
            state.loadingMeetingState = ThunkActionState.rejected;
        },
        [getOutputUrl.pending.toString()]: (state: IPlayerStore) => {
            state.loadingMeetingState = ThunkActionState.pending;
        },
    },
});

export const {
    outputUrlReceived,
} = playerSlice.actions;

export default playerSlice.reducer;