import { createAsyncThunk, createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { iAzureCommunicationServicesClient, iUtilitiesClient } from 'api/ApiClient';
import AzureCommunicationToken from "api/payloads/AzureCommunicationToken";
import { ThunkActionState } from "helpers/ThunkActionState";
import IAzureCommunicationServicesStoreProps from './props/AzureCommuncationServiceProps';

const sliceName = "AzureCommunicationServicesHelper";

const initialState : IAzureCommunicationServicesStoreProps = {
    loadingState : ThunkActionState.idle
};

export class AzureCommunicationServicesHelper {
    generateToken = createAsyncThunk(`${sliceName}/generateToken`, async (_, { dispatch }) => {
        const token : AzureCommunicationToken = await iAzureCommunicationServicesClient.generateAzureCommunicationServiceAccessToken();
        dispatch(azureCommuncationSlice.actions.updateToken(token));
        return token;
    });
    refreshToken = createAsyncThunk(`${sliceName}/refreshToken`, async (identity: string, { dispatch }) => {
        const token = await iAzureCommunicationServicesClient.refreshAzureCommunicationServiceAccessToken(identity);
        dispatch(azureCommuncationSlice.actions.updateToken(token));
        return token;
    });
    loadBotIdAsync = createAsyncThunk(`${sliceName}/loadBotId`, async (_, { dispatch }) => {
        const options = await iUtilitiesClient.getCommunicationOptions();
        dispatch(azureCommuncationSlice.actions.botIdLoaded(options.botId));
    });
}

export const azureCommuncationSlice = createSlice({
    name: sliceName,
    initialState,
    reducers:{
        updateToken: (state: IAzureCommunicationServicesStoreProps, action: PayloadAction<AzureCommunicationToken>) => {
            state.tokenData = action.payload;
        },
        botIdLoaded: (state: IAzureCommunicationServicesStoreProps, action: PayloadAction<string>) => {
            state.botId = action.payload;
        },
    }
});

const reducer = azureCommuncationSlice.reducer;
const azureCommunicationServicesHelper = new AzureCommunicationServicesHelper();

export {
    reducer,
    azureCommunicationServicesHelper,
};
