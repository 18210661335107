import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ThunkActionState } from "helpers/ThunkActionState";
import { iMeetingClient } from "../api/ApiClient";
import Event from "../api/payloads/Event";
import JoinMeetingByJoinUrlRequest from "../api/requests/JoinMeetingByJoinUrlRequest";
import JoinMeetingByTeamsIdRequest from "../api/requests/JoinMeetingByTeamsIdRequest";
import IJoinMeetingStore from "../features/main/meeting/join/props/IJoinMeetingStore";

const initialState: IJoinMeetingStore = {
    joiningMeetingState: ThunkActionState.idle,
    joiningMeetingByMeetingMsIdState: ThunkActionState.idle,
};

const sliceName = "join";

export const joinMeetingByJoinUrlAsync = createAsyncThunk(sliceName + "/joinMeetingByJoinUrl", async (request: JoinMeetingByJoinUrlRequest, { dispatch }) => {
    const meeting = await iMeetingClient.joinMeetingByJoinUrl(request);
    dispatch(slice.actions.meetingJoinedByUrl(meeting));
    return meeting;
});

export const joinMeetingByTeamsIdAsync = createAsyncThunk(sliceName+"/joinMeetingByTeamsIdAsync", async (request: JoinMeetingByTeamsIdRequest, { dispatch, rejectWithValue }) => {
    try {
        const meeting = await iMeetingClient.joinMeetingByMeetingMsId(request);
        dispatch(slice.actions.meetingJoinedByUrl(meeting));
        return meeting;
    } catch (error: any) {
        return rejectWithValue(error);
    }
});

const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        meetingJoinedByUrl: (state: IJoinMeetingStore, action: PayloadAction<Event>) => {
            state.joinedMeeting = action.payload;
        },
        meetingJoinedByMsId:(state:IJoinMeetingStore, action: PayloadAction<Event>) => {
            state.joinedMeeting = action.payload;
        },
        reset: () => initialState,
    },
    extraReducers: {
        [joinMeetingByJoinUrlAsync.fulfilled.toString()]: (state: IJoinMeetingStore) => {
            state.joiningMeetingState = ThunkActionState.fulfilled;
        },
        [joinMeetingByJoinUrlAsync.pending.toString()]: (state: IJoinMeetingStore) => {
            state.joiningMeetingState = ThunkActionState.pending;
        },
        [joinMeetingByJoinUrlAsync.rejected.toString()]: (state: IJoinMeetingStore) => {
            state.joiningMeetingState = ThunkActionState.rejected;
        },
        
        [joinMeetingByTeamsIdAsync.fulfilled.toString()]: (state: IJoinMeetingStore) => {
            state.joiningMeetingByMeetingMsIdState = ThunkActionState.fulfilled;
        },
        [joinMeetingByTeamsIdAsync.pending.toString()]: (state: IJoinMeetingStore) => {
            state.joiningMeetingByMeetingMsIdState = ThunkActionState.pending;
        },
        [joinMeetingByTeamsIdAsync.rejected.toString()]: (state: IJoinMeetingStore) => {
            state.joiningMeetingByMeetingMsIdState = ThunkActionState.rejected;
        },
    }
});

export const {
    reset,
} = slice.actions;

export default slice.reducer;