import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import {reducer as authReducer} from '../slices/AuthSlice';
import thunk from 'redux-thunk';
import {createLogger} from "redux-logger";
import scheduleMeetingReducer from "../features/main/meeting/schedule/scheduleMeetingSlice";
import organizationUsersReducer from '../features/main/organization/users/organizationUsersSlice';
import surveyReducer from '../features/teams/survey/SurveySlice';
import userMeetingsReducer from "../features/main/user/meetings/userMeetingsSlice";
import IUserStoreProps from "../common/props/store/IUserStoreProps";
import MeetingDetailsReducer from "../slices/MeetingDetailsSlice";
import IMeetingsStoreProps from '../common/props/store/IMeetingsStoreProps';
import MeetingReducer from "../slices/MeetingSlice";
import {reducer as UserReducer} from "../slices/UserSlice";
import StartNowReducer from "../features/main/meeting/startNow/StartNowSlice";
import JoinMeetingReducer from "../slices/JoinMeetingSlice";
import AzureApplicationConfigReducer from "../slices/AzureApplicationSlice";
import {reducer as StageReducer} from "../slices/StageSlice"
import StageDetailsReducer from "../slices/StageDetailsSlice";
import StageReducerProps from "../common/props/store/StageReducerProps";
import PlayerReducer from "../features/player/PlayerSlice";
import {reducer as communicationReducer} from '../features/main/communication/AzureCommunicationServicesSlice'
import PreMeetingSlice from 'slices/PreMeetingSlice';
import GeneralReducer from "slices/GeneralSlice";
import { reducer as visualSettingsReducer } from 'slices/visualSettings';

import { reducer as slatesReducer } from "../slices/slates";

const store = configureStore({
    reducer: {
        general: GeneralReducer,
        form: formReducer,
        auth: authReducer,
        survey: surveyReducer,
        organizationUsers: organizationUsersReducer,
        azureAppConfig: AzureApplicationConfigReducer,
        stage: combineReducers<StageReducerProps>({
            general: StageReducer,
            details: StageDetailsReducer,
        }),
        user: combineReducers<IUserStoreProps>({
            meetings: userMeetingsReducer,
            general : UserReducer
        }),
        meetings: combineReducers<IMeetingsStoreProps>({
            details: MeetingDetailsReducer,
            schedule: scheduleMeetingReducer,
            startNow: StartNowReducer,
            general: MeetingReducer,
            join: JoinMeetingReducer,
            preMeetingDetails : PreMeetingSlice,
        }),
        player: PlayerReducer,
        communication : communicationReducer,
        slates: slatesReducer,
        visualSettings : visualSettingsReducer,
    },
    middleware: (getDefaultMiddleware) => 
    {
        let result : any = getDefaultMiddleware().concat(thunk);
        if (process.env.NODE_ENV != 'production') {
            result = result.concat(createLogger())
        }
        return result;
    },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;