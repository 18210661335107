import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Slate } from "core/slates";

import {
  loadSlatesAsync,
  removeSlatesAsync,
  renameSlatesAsync,
} from "./thunks";

export interface ApyState {
  defaultSlates: Slate[];
  slates: Slate[] | null;
  uploadingProgress: Record<string, number>;
}

interface uploadTrackProps {
  id: string;
  progress: number;
}

const initialState: ApyState = {
  defaultSlates: [],
  slates: null,
  uploadingProgress: {},
};

export const slice = createSlice({
  name: "slates",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    uploadStart: (state, action) => {
      return {
        ...state,
        slates: state.slates
          ? [...action.payload, ...state.slates]
          : action.payload,
        uploadingProgress: {
          [action.payload.id]: 0,
        },
      };
    },
    uploadFinish: (state, action) => {
      return {
        ...state,
        slates: state.slates
          ? state.slates.map((slate: Slate) =>
              action.payload.fileName === slate.fileName
                ? action.payload
                : slate
            )
          : [action.payload],
      };
    },
    trackUploading: (state, action: PayloadAction<uploadTrackProps>) => {
      return {
        ...state,
        uploadingProgress: {
          ...state.uploadingProgress,
          [action.payload.id]: action.payload.progress,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadSlatesAsync.fulfilled, (state, action) => {
        return {
          ...state,
          defaultSlates: action.payload.defaultSlates,
          slates: action.payload.slates,
        };
      })
      .addCase(removeSlatesAsync.fulfilled, (state, action) => {
        return {
          ...state,
          slates: state.slates.filter(
            (slate: Slate) => slate.id !== action.payload.id
          ),
        };
      })
      .addCase(renameSlatesAsync.fulfilled, (state, action) => {
        return {
          ...state,
          slates: state.slates.map((slate: Slate) =>
            slate.id == action.payload.id ? action.payload : slate
          ),
        };
      });
  },
});
