export enum ErrorCode {
    Unknown = "unknown",
    Internal = "internal",
    InvalidData = "invalidData",
    AlreadyExists = "alreadyExists",
    MissingPermissions="missingGraphPermissions",

    EventNotFound = "eventNotFound",
    VenueNotFound = "venueNotFound",
    VenueNotLaunched = "venueNotLaunched",
    BotNotConnected = "botNotConnected",
    CallNotFound = "callNotFound",
    NoAvailableServer = "noAvailableServer",
    EventAlreadyHasRunningSession = "eventAlreadyHasRunningSession",
    SizeLimitExceeded = "sizeLimitExceeded",
    OnlineMeetingNotCreated = "onlineMeetingNotCreated",
    LogoNotFound = "logoNotFound",
    EventBlocked = 'eventBlocked',
    InvalidToken = "invalidToken",
    InvalidSubscription = "invalidSubscription",
};