import * as microsoftTeams from "@microsoft/teams-js";

export enum TaskResultType {
    failed,
    succeeded,
    canceled,
};

export interface TaskResult<T> {
    type: TaskResultType,
    payload: T,
};

export const getContextAsync = (): Promise<microsoftTeams.Context> => {
    return new Promise(resolve => {
        microsoftTeams.getContext((context) => {
            resolve(context);
        });
    });
};

export const getAuthTokenAsync = (): Promise<string> => {
    return new Promise((resolve, reject) => {
        microsoftTeams.authentication.getAuthToken({
            successCallback: (token: string) => resolve(token),
            failureCallback: (reason: string) => reject(reason), 
        });
    });
};

export const startTaskAsync = <T>(taskInfo: microsoftTeams.TaskInfo): Promise<T> => {
    return new Promise<T>((resolve, reject) => {
        microsoftTeams.tasks.startTask(taskInfo, (error, result) => {
            if (error) {
                reject(error);
            } else {
                const taskResult = JSON.parse(result) as TaskResult<any>;
                if (taskResult.type === TaskResultType.canceled) {
                    reject(TaskResultType.canceled);
                } else if (taskResult.type === TaskResultType.failed) {
                    reject(taskResult.payload as string);
                } else if (taskResult.type === TaskResultType.succeeded) {
                    resolve(taskResult.payload);
                }
            }
        });
    });
};

export const rejectTask = <T>(payload: T): void => {
    const taskResult: TaskResult<T> = {
        type: TaskResultType.failed,
        payload: payload,
    };
    microsoftTeams.tasks.submitTask(JSON.stringify(taskResult));
};

export const resolveTask = <T>(payload: T): void => {
    const taskResult: TaskResult<T> = {
        type: TaskResultType.succeeded,
        payload: payload,
    };
    microsoftTeams.tasks.submitTask(JSON.stringify(taskResult));
};

export const cancelTask = (): void => {
    const taskResult: TaskResult<undefined> = {
        type: TaskResultType.canceled,
        payload: undefined,
    };
    microsoftTeams.tasks.submitTask(JSON.stringify(taskResult));
};

export const buildDialogTaskInfo = (title: string, description: string, primaryButtonText: string = "Ok"): microsoftTeams.TaskInfo => {
    const taskInfo: microsoftTeams.TaskInfo = {
        width: microsoftTeams.TaskModuleDimension.Small,
        height: microsoftTeams.TaskModuleDimension.Small,
        card: JSON.stringify({
            type: "AdaptiveCard",
            body: [
                {
                    type: "TextBlock",
                    text: title,
                    wrap: true,
                    weight: "bolder",
                    size: "large",
                },
                {
                    type: "TextBlock",
                    text: description,
                    wrap: true,
                    isSubtle: true,
                }
            ],
            actions: [
                {
                    type: "Action.Submit",
                    title: primaryButtonText,
                },
            ],
            version: "1.0",
        }),
    };
    return taskInfo;
};