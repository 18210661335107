import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { iSurveyClient } from "../../../api/ApiClient";
import { ISurvey } from "../../../api/payloads/Survey";
import SaveSurveyRequest from "../../../api/requests/SaveSurveyRequest";
import SurveyRequest from "../../../api/requests/SurveyRequest";
import { ThunkActionState } from "../../../helpers/ThunkActionState";
import { ISurveyStore } from "../props/ISurveyStore";
import SurveysRequest from "api/requests/SurveysRequest";

const sliceName = "surveySlice";

const initialState: ISurveyStore = {
    loadSurveyState: ThunkActionState.idle,
    loadSurveysState: ThunkActionState.idle,
    saveSurveyResponseState: ThunkActionState.idle
};

export const loadSurveysAsync = createAsyncThunk(`${sliceName}/loadSurveys`, async (request: SurveysRequest, { dispatch, rejectWithValue }) => {
    let surveys = await iSurveyClient.getSurveys(request.organizationId);
    dispatch(surveySlice.actions.surveysLoaded(surveys));
    return surveys;
});

export const saveSurveyResponseAsync = createAsyncThunk(`${sliceName}/saveSurveyResponse`, async (request: SaveSurveyRequest, { dispatch }) => {
    try {
        const response = await iSurveyClient.saveSurveyResponse(request);
        return response;
    } catch (error) {
        return false;
    }
});

const surveySlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        surveysLoaded: (state: ISurveyStore, action: PayloadAction<ISurvey[]>) => {
            state.surveys = action.payload;
        },
        surveyLoaded: (state: ISurveyStore, action: PayloadAction<ISurvey>) => {
            state.survey = action.payload;
        },
    },
    extraReducers: {
        [`${sliceName}/loadSurveys/pending`]: (state: any) => {
            state.loadSurveysState = ThunkActionState.pending;
        },
        [`${sliceName}/loadSurveys/fulfilled`]: (state: any) => {
            state.loadSurveysState = ThunkActionState.fulfilled;
        },
        [`${sliceName}/loadSurveys/rejected`]: (state: any) => {
            state.loadSurveysState = ThunkActionState.rejected;
        },
        [`${sliceName}/loadSurvey/pending`]: (state: any) => {
            state.loadSurveyState = ThunkActionState.pending;
        },
        [`${sliceName}/loadSurvey/fulfilled`]: (state: any) => {
            state.loadSurveyState = ThunkActionState.fulfilled;
        },
        [`${sliceName}/loadSurvey/rejected`]: (state: any) => {
            state.loadSurveyState = ThunkActionState.rejected;
        },
        [`${sliceName}/saveSurveyResponse/pending`]: (state: any) => {
            state.saveSurveyResponseState = ThunkActionState.pending;
        },
        [`${sliceName}/saveSurveyResponse/fulfilled`]: (state: any) => {
            state.saveSurveyResponseState = ThunkActionState.fulfilled;
        },
        [`${sliceName}/saveSurveyResponse/rejected`]: (state: any) => {
            state.saveSurveyResponseState = ThunkActionState.rejected;
        },
    }
});

const reducer = surveySlice.reducer;

export const {
    surveyLoaded,
    surveysLoaded,
} = surveySlice.actions;

export default reducer;