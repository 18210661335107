import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { iUtilitiesClient } from "../../../api/ApiClient";
import { RootState } from "../../../app/store";
import AuthProvider from "../../../components/auth/AuthProvider";
import { AppType } from "../../AppType";

const mapStateToProps = (state: RootState) => ({
    authority: state.auth.authority,
    isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = {

};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type IProps = PropsFromRedux;

const CommunicationAuthSpeakersWrapper: React.FC<IProps> = (props: IProps) => {
    let searchParams = new URLSearchParams(window.location.search);
    let isSpeakersCall = false;

    if (searchParams.has('speakers')) {
        isSpeakersCall = searchParams.get('speakers') === 'true';
    }

    const [authority, setAuthority] = useState(props.authority);
    const [isAuthenticated, setIsAuthenticated] = useState(props.isAuthenticated);

    useEffect(() => {
        iUtilitiesClient.getGeneralConfiguration().then((response) => {
            setAuthority(response.authority);
            setIsAuthenticated(props.isAuthenticated);
        })
    }, [props.authority, props.isAuthenticated]);

    return (<>{authority && isSpeakersCall ?
        <AuthProvider
        authority={authority}
            applicationType={AppType.AzureCommunicationApp}>
            {isAuthenticated && (
                React.cloneElement((props as any).children, { isSpeakersCall: isSpeakersCall })
            )}
        </AuthProvider> : !isAuthenticated && ((props as any).children)} </>)
}

export default connector(CommunicationAuthSpeakersWrapper);
