import { Dictionary } from "@reduxjs/toolkit";
import axios, { AxiosInstance, CancelToken } from "axios";
import EventEmitter from "events";
import { EventTypes } from "./ApiClient";
import { ErrorCode } from "./payloads/ErrorCode";
import MetaverseApiError from "./payloads/MetaverseApiError";
import MetaverseApiResponse from "./payloads/MetaverseApiResponse";

class BaseApiClient extends EventEmitter {
    private _clientInstance: AxiosInstance;

    private _headers: Dictionary<any> = {
        "Content-Type": "application/json",
        "X-Client": "Main",
    };

    constructor(baseUrl?: string) {
        super();

        this._clientInstance = axios.create({
            baseURL: baseUrl,
            headers: this._headers,
        });

        this._clientInstance.interceptors.response.use(response => {
            if (response.status === 204) {
                response.data = null;
            }

            const metaverseApiResponse: MetaverseApiResponse = response.data;
            if (metaverseApiResponse.isSuccess === false) {
                const metaverseApiError = new MetaverseApiError(metaverseApiResponse.errorCode, metaverseApiResponse.payload);
                return Promise.reject(metaverseApiError);
            }

            return response;
        }, error => {
            if (axios.isCancel(error)) {
                return Promise.reject(error);
            }

            console.log(error);

            const metaverseApiResponse: MetaverseApiResponse = error?.response?.data;
            if (metaverseApiResponse) {
                if (metaverseApiResponse.errorCode === ErrorCode.InvalidToken) {
                    this.emit(EventTypes.InvalidToken);
                } else if (metaverseApiResponse.errorCode === ErrorCode.InvalidSubscription) {
                    this.emit(EventTypes.InvalidSubscription);
                }

                if (metaverseApiResponse.isSuccess === false) {
                    const metaverseApiError = new MetaverseApiError(metaverseApiResponse.errorCode, metaverseApiResponse.payload);
                    return Promise.reject(metaverseApiError);
                }
            }
            return Promise.reject(error);
        });
    }

    public setHeader(key: string, value: string | null) {
        this._clientInstance.defaults.headers[key] = value;
    }

    public async post<T = void>(action: string
        , body: any | null
        , cancelToken?: CancelToken
        , customHeaders?: Dictionary<any>): Promise<T> {
        const result = await this._clientInstance.post<MetaverseApiResponse<T>>(action, body, {
            headers: customHeaders,
            cancelToken: cancelToken,
        });
        return result.data.payload;
    }

    public async get<T = void>(action: string
        , cancelToken?: CancelToken
        , customHeaders?: Dictionary<any>): Promise<T> {

        const result = await this._clientInstance.get<MetaverseApiResponse<T>>(action, {
            headers: customHeaders,
            cancelToken: cancelToken,
        });
        return result.data.payload;
    }

    public async getBlob(action: string, cancelToken?: CancelToken, customHeaders?: Dictionary<any>): Promise<File> {
        const result = await this._clientInstance.get<Blob>(action, {
            responseType: "blob",
            headers: customHeaders,
            cancelToken: cancelToken,
        });

        let filename = "";
        const disposition = result.headers["content-disposition"];
        if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }

        return new File([result.data], filename);
    }

    public async patch<T = void>(action: string
        , body?: any
        , cancelToken?: CancelToken
        , customHeaders?: Dictionary<any>): Promise<T> {
        const result = await this._clientInstance.patch<MetaverseApiResponse<T>>(action, body, {
            headers: customHeaders,
            cancelToken: cancelToken,
        });
        return result.data.payload;
    }

    public async delete<T = void>(action: string
        , cancelToken?: CancelToken
        , customHeaders?: Dictionary<any>) : Promise<T> {
        const result = await this._clientInstance.delete<MetaverseApiResponse<T>>(action, {
            headers: customHeaders,
            cancelToken: cancelToken,
        });
        return result.data.payload;
    }
}

export default BaseApiClient;