import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet'
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './app/store';
import {
    BrowserRouter,
    Switch,
    Route
} from 'react-router-dom'
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "bootstrap/dist/css/bootstrap-utilities.min.css";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { enableMapSet } from 'immer';
import Loader from 'components/Loader';
import CommunicationAuthSpeakersWrapper from './features/main/communication/CommunicationAuthSpeakersWrapper';

const CommunicationContainer = React.lazy(() => import("features/main/communication/CommunicationContainer"));
const App = React.lazy(() => import("./features/main/App"));
const TeamsApp = React.lazy(() => import("./features/teams/App"));
const AppPlayer = React.lazy(() => import("./features/player/App"));

enableMapSet();
initializeIcons();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Suspense fallback={<Loader text="Signing you in.."/>}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/communication/:eventId" exact={true}>
                            <CommunicationAuthSpeakersWrapper>
                                <CommunicationContainer isSpeakersCall={false} />
                            </CommunicationAuthSpeakersWrapper>
                        </Route>
                        <Route path="/teams">
                            <TeamsApp/>
                        </Route>
                        <Route path="/player/:meetingId">
                            <Helmet>
                                <title>Touchcast for Microsoft Teams Player</title>
                            </Helmet>
                            <AppPlayer/>
                        </Route>
                        <Route path='/'>
                            <Helmet>
                                <title>Touchcast for Microsoft Teams</title>
                            </Helmet>
                            <App/>
                        </Route>
                    </Switch>
                </BrowserRouter>
            </Suspense>
        </Provider>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
