import { createAsyncThunk, createSlice, PayloadAction, createDraftSafeSelector } from "@reduxjs/toolkit";
import { IEventAdditionalInfo } from "common/props/store/IPreMeetingStore";
import { uploadFileAsync } from "app/AzureBlobClient";
import { meetingUpdated } from "slices/MeetingDetailsSlice"
import IMeetingStore from "slices/props/IMeetingStore";

import { iMeetingClient, iCommandClient, iUploadsClient, apiClient, iUserClient } from "api/ApiClient";
import PrepareUploadRequest from "api/requests/PrepareUploadRequest";
import FileUploadFinishRequest from "api/requests/FileUploadFinishRequest";
import CreateMeetingByMsIdRequest from "api/requests/CreateMeetingByMsIdRequest";
import UpdateEventByMsIdRequest from "api/requests/UpdateEventByMsIdRequest";
import FileUploadStartResponse from "api/payloads/FileUploadStartResponse";
import EventsConfig from "api/payloads/EventsConfig";
import CommandConfig from "api/payloads/CommandConfig";
import { UploadTarget } from "api/payloads/UploadTarget";
import { ThunkActionState } from "helpers/ThunkActionState";
import { RootState } from "app/store";
import { IEventQuestion } from "@touchcastllc/touchcast-metaverse-widget-context";

const initialState: IMeetingStore = {
    loadingMeetingConfigState: ThunkActionState.idle,
    creatingMeetingByMsIdState: ThunkActionState.idle,
    updatingMeetingByMsIdState: ThunkActionState.idle,
    showQuestionsTab: false,
    questions: {
        count: 0,
        currentPage: 0,
        offset: 0,
        pagesCount: 0,
        totalCount: 0,
        items: [] as IEventQuestion[],
        isQuestionsLoading: false
    },
};

export const loadMeetingConfigAsync = createAsyncThunk('meetings/loadMeetingConfig', async (_, { dispatch }) => {
    const meetingConfig = await iMeetingClient.getMeetingConfig();
    dispatch(meetingSlice.actions.meetingConfigLoaded(meetingConfig));
});

export const loadCommandConfigAsync = createAsyncThunk('meetings/loadCommandConfig', async (_, { dispatch }) => {
    const commandConfig = await iCommandClient.getCommandConfig();
    dispatch(meetingSlice.actions.commandConfigLoaded(commandConfig));
});

export const createMeetingByMsIdAsync = createAsyncThunk("meetings/createMeetingByMsId", async (request: CreateMeetingByMsIdRequest, { rejectWithValue }) => {
    try {
        const meeting = await iMeetingClient.createMeetingByMsId(request);
        return meeting;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const updateEventByMsIdAsync = createAsyncThunk("meetings/updateEventByMsId", async (request: UpdateEventByMsIdRequest) => {
    const meeting = await iMeetingClient.updateEventByMsId(request.msId, request);
    return meeting;
});

export const uploadLogoAsync = createAsyncThunk("meetings/uploadLogo", async (payload: {
    organizationId: string,
    file: File,
}, { rejectWithValue }) => {
    try {
        const {organizationId, file} = payload;

        const prepareUploadRequest: PrepareUploadRequest = {
            organizationId: organizationId,
            target: UploadTarget.Logo,
            filename: file.name,
        };
        const prepareUploadResponse = await iUploadsClient.prepareUpload(prepareUploadRequest);
        
        const fileUploadStartResponse = await apiClient.get<FileUploadStartResponse>(prepareUploadResponse.uploadStartUrl);

        await uploadFileAsync(fileUploadStartResponse.azure.sas_uri, file);

        const fileUploadFinishRequest: FileUploadFinishRequest = {
            file_id: fileUploadStartResponse.file_id,
        };

        await apiClient.post(prepareUploadResponse.uploadFinishUrl, fileUploadFinishRequest);

        const logo = await iUserClient.createLogo(fileUploadStartResponse.file_id);
        return logo;
    } catch(error) {
        return rejectWithValue(error);
    }
});

export const setLogoAsync = createAsyncThunk("meetings/setLogo", async (payload: {
    eventId: string,
    logoId?: string,
}, { dispatch, rejectWithValue }) => {
    try {
        const {eventId, logoId} = payload;

        var event = await iMeetingClient.setLogo(eventId, logoId);
        dispatch(meetingUpdated(event));
        return;
    } catch(error) {
        return rejectWithValue(error);
    }
});
export const attachSlatesAsync = createAsyncThunk("meeting/attachSlates", async(payload:{
    eventId:string,
    slatesIds:Array<string>
}, { dispatch, rejectWithValue }) =>{
    const {eventId, slatesIds} = payload;

    var event = await iMeetingClient.attachSlates(eventId, slatesIds);
    dispatch(meetingUpdated(event));
    return;
});
export const updateSpeakersCallWithAdditionalInfo = createAsyncThunk("meetings/updateSpeakersCallWithAdditionalInfo", async (payload : {eventId: string, request: IEventAdditionalInfo}, { rejectWithValue }) => {
    try {
        await iMeetingClient.updateSpeakersAdditionalInfo(payload.eventId, payload.request);
        return;
    } catch(error) {
        return rejectWithValue(error);
    }  
});

export const updateListenersCallWithAdditionalInfo = createAsyncThunk("meetings/updateListenersCallWithAdditionalInfo", async (payload : {eventId: string, request: IEventAdditionalInfo}, { rejectWithValue }) => {
    try {
        await iMeetingClient.updateListenersAdditionalInfo(payload.eventId, payload.request);
        return;
    } catch(error) {
        return rejectWithValue(error);
    }
});

export const loadQuestionsAnalyticsAsync = createAsyncThunk("meetings/loadQuestionsAnalytics", async (payload: string) => {
    const questions = await iMeetingClient.getQuestionsAnalytics(payload)
    return questions;
});

export const loadQuestionsCountAsync = createAsyncThunk("meetings/loadQuestionsCount", async (payload: string) => {
    const questions = await iMeetingClient.getQuestionsCount(payload)
    return questions;
});

export const downloadCsvFileAsync = createAsyncThunk("meetings/eventQuestionsAnalytics/downloadCsvFile", async (eventId: string) => {
    const csvFile = await iMeetingClient.downloadCsvFile(eventId);
    return csvFile;
});

const meetingSlice = createSlice({
    name: "meetingSlice",
    initialState,
    reducers: {
        meetingConfigLoaded: (state: IMeetingStore, action: PayloadAction<EventsConfig>) => {
            state.meetingConfig = action.payload;
        },
        commandConfigLoaded: (state: IMeetingStore, action: PayloadAction<CommandConfig>) => {
            state.commandConfig = action.payload;
        },
        eventLogoUpdated: (state: IMeetingStore, action: PayloadAction<string>) => {
        }
    },
    extraReducers: {
        [loadMeetingConfigAsync.pending.toString()]: (state: IMeetingStore) => {
            state.loadingMeetingConfigState = ThunkActionState.pending;
        },
        [loadMeetingConfigAsync.fulfilled.toString()]: (state: IMeetingStore) => {
            state.loadingMeetingConfigState = ThunkActionState.fulfilled;
        },
        [loadMeetingConfigAsync.rejected.toString()]: (state: IMeetingStore) => {
            state.loadingMeetingConfigState = ThunkActionState.rejected;
        },

        [createMeetingByMsIdAsync.pending.toString()]: (state: IMeetingStore) => {
            state.creatingMeetingByMsIdState = ThunkActionState.pending;
        },
        [createMeetingByMsIdAsync.fulfilled.toString()]: (state: IMeetingStore) => {
            state.creatingMeetingByMsIdState = ThunkActionState.fulfilled;
        },
        [createMeetingByMsIdAsync.rejected.toString()]: (state: IMeetingStore) => {
            state.creatingMeetingByMsIdState = ThunkActionState.rejected;
        },

        [updateEventByMsIdAsync.pending.toString()]: (state: IMeetingStore) => {
            state.updatingMeetingByMsIdState = ThunkActionState.pending;
        },
        [updateEventByMsIdAsync.fulfilled.toString()]: (state: IMeetingStore) => {
            state.updatingMeetingByMsIdState = ThunkActionState.fulfilled;
        },
        [updateEventByMsIdAsync.rejected.toString()]: (state: IMeetingStore) => {
            state.updatingMeetingByMsIdState = ThunkActionState.rejected;
        },

        [loadQuestionsAnalyticsAsync.pending.toString()]: (state: IMeetingStore) => {
            state.questions.isQuestionsLoading = true;
        },
        [loadQuestionsAnalyticsAsync.fulfilled.toString()]: (state: IMeetingStore, { payload }) => {
            state.questions = {
                ...payload,
                isQuestionsLoading: false
            };
        },
        [loadQuestionsAnalyticsAsync.rejected.toString()]: (state: IMeetingStore) => {
            state.questions.isQuestionsLoading = false
        },
        [loadQuestionsCountAsync.fulfilled.toString()]: (state: IMeetingStore, { payload }) => {
            state.showQuestionsTab = !!payload
        }
    }
});

const selectMeetings = (state: RootState) => state.meetings

export const getQuestionsList = createDraftSafeSelector(
    selectMeetings,
    (state) => state.general.questions
)

export const getShowQuestionsTab = createDraftSafeSelector(
    selectMeetings,
    (state) => state.general.showQuestionsTab
)


export const {
    eventLogoUpdated,
} = meetingSlice.actions;

export default meetingSlice.reducer;