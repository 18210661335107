import { SpeakersInfo } from "@touchcastllc/touchcast-metaverse-widget-context"

export interface VenueMetaData {
    speakerLayout: VenueLayout,
    tags: Array<string>,
    speakersInfo: SpeakersInfo,
    guestsCount?: number,
    screensCount?: number
};

export enum VenueLayout {
    SingleSpeaker = "singleSpeaker",
    MultiSpeaker = "multiSpeaker",
}