import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import IOrganizationUsersStore from "./props/IOrganizationUsersStore";
import { iOrganizationClient } from "../../../../api/ApiClient";
import { User } from "../../../../api/payloads/User";
import { RootState } from "app/store";

const initialState: IOrganizationUsersStore = {
    loadingUsersState: 'idle',
    users: [],
    searchingUsersState: 'idle',
    searchedUsers: [],
    currentPage: 0,
    pageSize: 50,
    totalUsersCount: 0,
    initializingState: 'idle',
}

export const initializeAsync = createAsyncThunk('organizationUsers/initializing', async (_, { dispatch }) => {
    const usersCount = await iOrganizationClient.getUsersCount();
    dispatch(organizationUsersSlice.actions.initialized(usersCount));
});

export const loadUsersAsync = createAsyncThunk("organizationUsers/loadingUsers", async (_, { getState, dispatch }) => {
    const state = (getState() as RootState).organizationUsers;
    const users = await iOrganizationClient.getUsers(state.currentPage * state.pageSize, state.pageSize);
    dispatch(organizationUsersSlice.actions.usersLoaded(users));
});

export const searchUsersAsync = createAsyncThunk("organizationUsers/searchingUsers", async (searchString: string, { dispatch }) => {
    dispatch(organizationUsersSlice.actions.searchStarted(searchString));
    const searchedUsers = await iOrganizationClient.searchUsers(searchString);
    dispatch(organizationUsersSlice.actions.searchedUsersFound(searchedUsers));
    return searchedUsers;
});

export const selectCanLoadMore = (state: IOrganizationUsersStore) => state.currentPage * state.pageSize < state.totalUsersCount;

const organizationUsersSlice = createSlice({
    name: "organizationUsers",
    initialState,
    reducers: {
        initialized: (state: IOrganizationUsersStore, action: PayloadAction<number>) => {
            state.totalUsersCount = action.payload;
            state.currentPage = 0;
            state.loadingUsersState = 'idle';
            state.users = [];
        },
        usersLoaded: (state: IOrganizationUsersStore, action: PayloadAction<Array<User>>) => {
            state.users = state.users.concat(action.payload);
            state.currentPage++;
        },
        searchStarted: (state: IOrganizationUsersStore, action: PayloadAction<string>) => {
            state.searchString = action.payload;
        },
        searchedUsersFound: (state: IOrganizationUsersStore, action: PayloadAction<Array<User>>) => {
            state.searchedUsers = action.payload;
        },
        reset: () => initialState,
    },
    extraReducers: {
        [initializeAsync.pending.toString()]: (state: IOrganizationUsersStore) => {
            state.initializingState = 'loading';
        },
        [initializeAsync.fulfilled.toString()]: (state: IOrganizationUsersStore) => {
            state.initializingState = 'succeeded';
        },
        [initializeAsync.rejected.toString()]: (state: IOrganizationUsersStore) => {
            state.initializingState = 'failed';
        },

        [loadUsersAsync.pending.toString()]: (state: IOrganizationUsersStore) => {
            state.loadingUsersState = 'loading';
        },
        [loadUsersAsync.fulfilled.toString()]: (state: IOrganizationUsersStore) => {
            state.loadingUsersState = 'succeeded';
        },
        [loadUsersAsync.rejected.toString()]: (state: IOrganizationUsersStore) => {
            state.loadingUsersState = 'failed';
        },

        [searchUsersAsync.pending.toString()]: (state: IOrganizationUsersStore) => {
            state.searchingUsersState = 'loading';
        },
        [searchUsersAsync.fulfilled.toString()]: (state: IOrganizationUsersStore) => {
            state.searchingUsersState = 'succeeded';
        },
        [searchUsersAsync.rejected.toString()]: (state: IOrganizationUsersStore) => {
            state.searchingUsersState = 'failed';
        },
    }
});

export const {
    reset,
} = organizationUsersSlice.actions;

export default organizationUsersSlice.reducer;