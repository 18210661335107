import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import AzureApplicationConfiguration from "api/payloads/AzureApplicationConfiguration";
import { ThunkActionState } from "helpers/ThunkActionState";
import { iAzureApplicationClient } from "../api/ApiClient";

const sliceName = "azureApplicationClient";
const initialState: AzureApplicationConfiguration = {
};

export const getAzureApplicationConfigurationAsync = createAsyncThunk(sliceName + "/azureApplicationConfiguration", async (_, { dispatch }) => {
    const appConfig = await iAzureApplicationClient.getAzureAppInfo();
    dispatch(slice.actions.azureAppConfiguration(appConfig));
});

const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        azureAppConfiguration:(state:AzureApplicationConfiguration, action: PayloadAction<AzureApplicationConfiguration>) => {
            console.log("azureAppConfiguration");
            console.log(action.payload);
            state.appId = action.payload.appId;
        },
    },
    extraReducers: {
        
    }
});

export default slice.reducer;